// src/components/SoundBoard.js

import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion"; // For animations

let audio = null;

// Function to play sound
function playSound(location) {
  const url = "https://server.stavbot.com";
  if (audio) {
    audio.pause();
    audio.currentTime = 0;
  }
  audio = new Audio(url + location);
  audio.play();
}

// Styled SoundButton component using MUI's Button
const SoundButton = ({ sound }) => {
  const theme = useTheme();

  return (
    <motion.div whileHover={{ scale: 1.05 }} style={{ height: "100%" }}>
      <Button
        variant="contained"
        onClick={() => playSound(sound.fileLocation)}
        aria-label={`Play ${sound.title}`}
        sx={{
          width: "100%",
          minHeight: "100px", // Increased the minimum height for taller buttons
          borderRadius: "8px",
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
          textTransform: "none",
          fontSize: "14px",
          padding: "8px",
          whiteSpace: "normal", // Allow text to wrap
          textAlign: "center",  // Center-align text
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        <Typography variant="body2">
          {sound.title}
        </Typography>
      </Button>
    </motion.div>
  );
};

// SoundBoard component using CSS Grid
const SoundBoard = ({ sounds }) => {
  const theme = useTheme();

  if (!sounds || sounds.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No sounds available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gap: "10px",
        padding: "10px 0",
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",    // 1 button per row on extra-small screens
          sm: "repeat(2, 1fr)",    // 2 buttons per row on small screens
          md: "repeat(2, 1fr)",    // 2 buttons per row on medium screens
          lg: "repeat(3, 1fr)",    // 3 buttons per row on large screens
          xl: "repeat(3, 1fr)",    // 3 buttons per row on extra-large screens
        },
        alignItems: "stretch", // Ensure all items stretch to the same height
      }}
    >
      {sounds.map((sound, index) => (
        <Box key={index} sx={{ width: "100%", height: "100%" }}>
          <SoundButton sound={sound} />
        </Box>
      ))}
    </Box>
  );
};

export default SoundBoard;
