// src/index.js

import React from "react";
import ReactDOM from "react-dom/client"; // Updated import for React 18
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark", // You can switch to "light" if preferred
    primary: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Provides a consistent baseline for styling */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
