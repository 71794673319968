// src/Pages/LoginPage.js

import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Parallax } from "react-parallax";
import { motion } from "framer-motion";
import { FaGoogle } from "react-icons/fa"; // Using react-icons for better icon flexibility
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../firebase";
import { Helmet } from "react-helmet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/system";
import { ScrollLink } from "react-scroll";
import Container from "@mui/material/Container";

// Import AwesomeButton and its CSS theme
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css"; // Choose the theme you prefer

// Background image URLs (you can replace these with your own images)
const backgroundImage1 =
  "https://pbs.twimg.com/card_img/1840180496954793984/Pz5x4Pdc?format=jpg&name=medium";
const backgroundImage2 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2o9-8qfqS65h_GqiMAlFf6l1H1DOlQ8R5eA&s";

// Styled Components
const AnimatedContainer = styled(motion.div)(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.6)",
  padding: "40px",
  borderRadius: "15px",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  backdropFilter: "blur(8px)",
  WebkitBackdropFilter: "blur(8px)",
  border: "1px solid rgba(255, 255, 255, 0.18)",
  textAlign: "center",
  color: "#fff",
  maxWidth: "400px",
  width: "90%",
}));

const LoginPage = () => {
  const [error, setError] = useState(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      // Redirect handled by App.js based on auth state
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError("Failed to sign in. Please try again.");
    }
  };

  return (
    <Box sx={{ height: "100vh", width: "100%", overflow: "hidden" }}>
      <Helmet>
        <title>Sign In</title>
        <meta
          name="description"
          content="Sign in to access your account with a modern and animated login page."
        />
      </Helmet>
      <Parallax bgImage={backgroundImage1} strength={500}>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay for better text visibility
            padding: isSmallScreen ? "20px" : "0",
          }}
        >
          <AnimatedContainer
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            whileHover={{ scale: 1.02 }}
            sx={{
              width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
            }}
          >
            {/* Logo Image */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <img
                style={{ width: 80, height: 80, borderRadius: "50%" }}
                src="https://pbs.twimg.com/profile_images/1503095773059244036/xof9dI-A_400x400.jpg"
                alt="logo"
              />
            </Box>

            {/* Welcome Text */}
            <Typography
              variant="h4"
              gutterBottom
              component={motion.div}
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              Welcome Back
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              component={motion.div}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Sign in to continue
            </Typography>

            {/* Error Message */}
            {error && (
              <Typography
                variant="body2"
                color="error"
                gutterBottom
                component={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                {error}
              </Typography>
            )}

            {/* Awesome Google Sign-In Button */}
            <Box mt={3}>
              <AwesomeButton
                type="primary"
                size="medium"
                onPress={handleGoogleSignIn}
                aria-label="Sign in with Google"
              >
Login              </AwesomeButton>
            </Box>

            {/* Social Media Icons */}
            <Box mt={2} display="flex" justifyContent="center" gap={2}>
             
            </Box>
          </AnimatedContainer>
        </Box>
      </Parallax>

      {/* Additional Parallax Layer for Depth */}
      <Parallax bgImage={backgroundImage2} strength={200}>
        <Box sx={{ height: "50vh" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography
              variant="h5"
              color="#fff"
              component={motion.div}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              animate={{
                y: [0, -10, 0], // Bounce effect
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              Scroll Down to Explore More
            </Typography>
          </Box>
        </Box>
      </Parallax>

      {/* Cool Footer Animation */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          textAlign: "center",
          padding: "10px 0",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <Typography
          variant="body2"
          color="#fff"
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          © {new Date().getFullYear()} Your Company. All rights reserved.
        </Typography>
      </Box>

      {/* Scroll Down Indicator */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <ScrollLink to="more-content" smooth={true} duration={500}>
          <IconButton aria-label="scroll down" sx={{ color: "#fff" }}>
            <motion.div
              animate={{ y: [0, 10, 0] }}
              transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
            >
              <AccountCircleIcon fontSize="large" />
            </motion.div>
          </IconButton>
        </ScrollLink>
      </Box>

      {/* Additional Content Section */}
      <Box id="more-content" sx={{ height: "100vh", backgroundColor: "#121212" }}>
        {/* Your additional content here */}
        <Container sx={{ paddingTop: "50px" }}>
          <Typography variant="h3" color="#fff" gutterBottom>
            Explore More Features
          </Typography>
          <Typography variant="body1" color="#ccc">
            {/* Add more descriptive content here */}
            This is an additional content section to showcase more features of your application.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default LoginPage;
