// src/App.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme"; // Import the custom theme
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { motion } from "framer-motion"; // For animations

const App = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  const [message, setMessage] = useState("");
  const [summary, setSummary] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleMessageChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) { // Increased character limit
      setError("Message cannot exceed 1000 characters.");
      return;
    }
    setMessage(value);
    setError(""); // Clear error on input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) {
      setError("Message cannot be empty.");
      return;
    }

    setLoading(true);
    setError("");
    setSummary("");
    setAudioUrl("");

    try {
      // Send the message to the backend API
      const response = await axios.post("https://api.stavbot.com/api/interpret", {
        text: message,
        username: user ? user.displayName : "Anonymous",
      });

      const { summary } = response.data;
      setSummary(summary);

      // Generate audio using ElevenLabs API
      const audioResponse = await fetch(
        "https://api.elevenlabs.io/v1/text-to-speech/M6Tg6dgaTEln2kszXt9c",
        {
          method: "POST",
          headers: {
            "xi-api-key": "b6380125681f6a1dfd020d10ed491a4d",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: summary,
            voice_settings: {
              similarity_boost: 0.5,
              stability: 0.3,
              style: 0.25,
            },
          }),
        }
      );

      if (!audioResponse.ok) {
        throw new Error("Failed to generate audio.");
      }

      const audioBlob = await audioResponse.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      setMessage("");
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://lastfm.freetls.fastly.net/i/u/ar0/8c796eed7cb466d75caafda6f9b788e3.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              backgroundColor: "rgba(255, 255, 255, 0.85)",
              borderRadius: 3,
            }}
          >
            <Typography variant="h4" align="center" gutterBottom>
              Ask Nick a Question
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Nick will respond using the power of AI.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Your Message"
                  placeholder="Ask Nick anything..."
                  value={message}
                  onChange={handleMessageChange}
                  variant="outlined"
                  inputProps={{ maxLength: 1000 }}
                  helperText={`${message.length}/1000 characters`}
                />
              </Box>
              {error && (
                <Box sx={{ mb: 2 }}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  sx={{
                    paddingX: 4,
                    paddingY: 1.5,
                    fontSize: "1rem",
                    borderRadius: 2,
                    boxShadow: 3,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      boxShadow: 6,
                      transform: "translateY(-2px)",
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                </Button>
              </Box>
            </form>
            {summary && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom align="center">
                    Nick's Response
                  </Typography>
                  <Typography variant="body1" align="center">
                    {summary}
                  </Typography>
                </Box>
              </motion.div>
            )}
            {audioUrl && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Box sx={{ mt: 4, textAlign: "center" }}>
                  <Typography variant="h6" gutterBottom>
                    Listen to Nick's Response
                  </Typography>
                  <audio src={audioUrl} controls style={{ width: "100%" }} />
                </Box>
              </motion.div>
            )}
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default App;
