// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import TextToSpeechNick from "../Pages/NickAI/TalkNick";
import SignIn from "../Pages/SignIn/SignIn";
import { setPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import NavBar from "../components/NavBar";
import Nick from "../Pages/Nick";
import Stav from "../Pages/Stav";
import Adam from "../Pages/Adam";
import Grid from "@mui/material/Grid";
import MetaTags from "react-meta-tags";

function App() {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Set Firebase authentication persistence
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setAuthUser(user);
          setLoading(false);
          // If user is authenticated and is on the signin page, redirect to home
          if (user && location.pathname === "/signin") {
            navigate("/");
          }
        });
        return unsubscribe;
      })
      .catch((error) => {
        console.error("Failed to set persistence:", error);
        setLoading(false);
      });
  }, [navigate, location.pathname]);

  useEffect(() => {
    // If user is not authenticated and not on signin page, redirect to signin
    if (!authUser && !loading && location.pathname !== "/signin") {
      navigate("/signin");
    }
  }, [authUser, loading, navigate, location.pathname]);

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          maxHeight: "100vh",
          backgroundColor: "#000", // Black background for consistency
          color: "#fff",
        }}
      >
        <p>Loading...</p>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        
        minHeight: "100vh",
        backgroundColor: "#000", // Black background to match Matrix effect
      }}
    >
      <MetaTags>
        <title>Stavbot - Talk to Nick Mullen</title>
        <meta
          name="description"
          content="Welcome to Stavbot! Talk to Nick Mullen and listen to his humorous responses. Log in and submit your questions."
        />
        <meta
          name="keywords"
          content="Stavbot, Nick Mullen, talk, questions, humor, responses, login"
        />
      </MetaTags>
      <Grid item sx={{ flexGrow: 1 }}>
        <Routes>
          <Route path="/" element={<TextToSpeechNick />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/nick" element={<Nick />} />
          <Route path="/stav" element={<Stav />} />
          <Route path="/adam" element={<Adam />} />
          {/* Optional: Redirect unknown routes to home or a 404 page */}
          <Route path="*" element={<TextToSpeechNick />} />
        </Routes>
      </Grid>
      <Grid item>
        {/* Conditionally render NavBar if not on the signin page */}
        {location.pathname !== "/signin" && <NavBar />}
      </Grid>
    </Grid>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
