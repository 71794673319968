// src/Components/SimpleBottomNavigation.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import MicrowaveRoundedIcon from '@mui/icons-material/MicrowaveRounded';
import MoneyIcon from '@mui/icons-material/Money';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getAuth, signOut } from 'firebase/auth';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/themes/theme-blue.css'; // Import the theme you want to use
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';

const FullWidthBox = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
  background: 'linear-gradient(to top, #ffffff, #f0f0f0)', // Example gradient
  boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
  zIndex: 1000,
}));

const SimpleBottomNavigation = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const auth = getAuth();

  const navigationItems = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      route: '/',
    },
    {
      label: 'Adam',
      icon: <MoneyIcon />,
      route: '/adam',
    },
    {
      label: 'Stav',
      icon: <FoodBankIcon />,
      route: '/stav',
    },
    {
      label: 'Nick',
      icon: <MicrowaveRoundedIcon />,
      route: '/nick',
    },
  ];

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    const selectedItem = navigationItems[newValue];
    if (selectedItem) {
      navigate(selectedItem.route);
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful, navigate to sign-in page
        navigate('/signin');
      })
      .catch((error) => {
        // An error occurred during sign-out
        console.error('Error signing out:', error);
      });
  };

  return (
    <FullWidthBox>
      <BottomNavigation
        value={value}
        onChange={handleNavigationChange}
        showLabels
        sx={{
          width: '100%',
          backgroundColor: 'transparent', // Make background transparent to show gradient
          boxShadow: 'none',
        }}
      >
        {navigationItems.map((item, index) => (
          <Tooltip title={item.label} key={index}>
            <BottomNavigationAction
              label={item.label}
              icon={item.icon}
              aria-label={item.label}
              sx={{
                color: value === index ? '#1976d2' : '#757575', // Active and inactive colors
                '&.Mui-selected': {
                  color: '#1976d2',
                },
              }}
            />
          </Tooltip>
        ))}
        <Tooltip title="Logout">
          <BottomNavigationAction
            label="Logout"
            icon={<ExitToAppIcon />}
            aria-label="Logout"
            onClick={handleLogout}
            sx={{
              color: '#757575',
              '&:hover': {
                color: '#d32f2f',
              },
            }}
          />
        </Tooltip>
      </BottomNavigation>
    </FullWidthBox>
  );
};

export default SimpleBottomNavigation;
